var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('PageHeader', {
    attrs: {
      "title": "Portfolio List"
    }
  }), _c('div', {
    staticClass: "content"
  }, [_c('div', {
    staticClass: "block block-rounded"
  }, [_vm._m(0), _c('div', {
    staticClass: "block-content"
  }, [_c('DataTable', {
    attrs: {
      "items": _vm.assetGroups,
      "total": _vm.total,
      "loading": _vm.loadingAction.list,
      "columns": _vm.columns,
      "actions": _vm.actions,
      "shallow-search": true
    },
    scopedSlots: _vm._u([{
      key: "name",
      fn: function (slotProps) {
        return [_c('router-link', {
          staticClass: "font-w600",
          attrs: {
            "to": {
              name: 'portfolio-dashboard',
              params: {
                id: slotProps.data._id
              }
            }
          }
        }, [_vm._v(_vm._s(slotProps.data.name))])];
      }
    }])
  })], 1)])]), _c('ConfirmModal', {
    attrs: {
      "open": !!_vm.modals.remove,
      "title": "Delete Portfolio",
      "text": `Please confirm you would like to remove portfolio: <strong>${_vm.modals.remove.name}</strong><br/><br/> This <strong>will not</strong> delete the assets assigned to this portfolio. Any assigned assets will simply be unassigned.`
    },
    on: {
      "close": function () {
        _vm.modals.remove = false;
      },
      "submit": _vm.onRemove
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "block-header block-header-default"
  }, [_c('h3', {
    staticClass: "block-title"
  }, [_vm._v("All Portfolios")])]);

}]

export { render, staticRenderFns }